import React from "react";
import { LazyMotion, domAnimation } from "motion/react";
import Header from "@/layouts/partials/header";
import Footer from "@/layouts/partials/footer";
import "@resources/styles/app.css";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => (
  <LazyMotion features={domAnimation}>
    <noscript>
      <style>
        {`.motion { 
          opacity: 1 !important;
          transform: translateY(0vh) translateZ(0px) !important;
         }`}
      </style>
    </noscript>
    <div className="overflow-hidden">
      <div className="flex flex-col min-h-screen">
        <Header />
        <main className="flex flex-col items-stretch justify-center flex-1">
          {children}
        </main>
      </div>
      <Footer />
    </div>
  </LazyMotion>
);

export default Layout;
